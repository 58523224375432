@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

body {
	background-color: #fff;
}

@import url(https://fonts.googleapis.com/css?family=Fjalla+One&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Trirong);
@import url(https://fonts.googleapis.com/css?family=Arima+Madurai:100,200,300,400,500,700,800,900);

/* $mainfont: "Nunito", sans-serif; */
$boldfont: "Fjalla One", sans-serif;
$mainfont: "Arima Madurai", serif;
$titlefont: "Trirong", serif;
$menuheight: 70px;
$logoheight: 150px;
$menuposition: $logoheight - 40px;

$gold: #D3A04C;
$grey: #6a6a6a;
$white: #fff;

@mixin menufont ($size) {
	font-family: $mainfont;
	font-size: $size;
	color: #565656;
}
@mixin animate ($speed) {
	-webkit-transition: all $speed ease-in-out;
	-moz-transition: all $speed ease-in-out;
	-o-transition: all $speed ease-in-out;
	transition: all $speed ease-in-out;
}
@mixin background-image ($url) {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url($url);
}
@mixin border-radius($radius) {
	border-radius: $radius;
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
}

@import "swiper";
@import 'slick';
@import 'gallery';

 a {
 	color: $grey;
 }

 p {
 	@include menufont(1.8rem);
 }

 section {
 	background-color: $white;
 	display: block;
 	overflow: hidden;
 	position: relative;
 }

 .main-block {
 	position: relative;
 	z-index: 1;
 	background-color: $white;
 }

 h1, h2 {
 	color: $white;
	font-family: $titlefont;
	text-align: center;
	font-size: 4.5rem;
 }

 .grey {
 	color: $grey
 }


@import "form";
@import "pages";
@import "footer";
@import "header";
@import "sections";
@import "portfolio";
@import 'slicknav';
@import "queries";
