::-webkit-input-placeholder {
    color:    $grey;
}
:-moz-placeholder {
    color:    $grey;
}
::-moz-placeholder {
    color:    $grey;
}
:-ms-input-placeholder {
    color:    $grey;
}
.error {
	width: 70%;
	display: block !important;
	background-color: #BF8C8C;
	overflow: hidden;
	text-align: center;
	margin: 20px auto 10px auto !important;
	font-size: 1.2rem;
	color: #fff;
	padding: 10px;
	text-transform: uppercase;
	clear: both;
	@include border-radius(10px);
}
.success {
	width: 70%;
	display: block !important;
	background-color: #98B598;
	overflow: hidden;
	text-align: center;
	margin: 20px auto 10px auto !important;
	font-size: 1.2rem;
	color: #fff;
	padding: 10px;
	text-transform: uppercase; 
	clear: both;
	@include border-radius(10px);
}
#c-form {
	display: block;
	margin: 0 auto;
	max-width: 80%;
	overflow: hidden;
}
#payment-form2 {
	max-width: 340px;
	margin: 0 auto;
}
.form-info {
	clear: both;
	display: block;
	width: 100%;
	& h4 {
		font-size: 0.9rem;
		font-weight: 600;
		color: $grey;
		text-align: left;
	}
	& p {
		font-size: 0.8rem;
		color: $grey;
		text-align: left;
		margin: 5px;
	}
}
.slash {
	float: left;
	width: 2%;
	display: inline-block;
	height: 42px;
	line-height: 42px;
	margin: 20px auto;
}
.form-row {
	width: 100%;
	display: block;
	&.cvc {
		width: 30%;
		float: left;
		margin-right: 2%;
	}
	&.half {
		width: 49.2%;
		margin-right: 0.8%;
		float: left;
		&.last {
			margin-left: 0.8%;
			margin-right: 0;
		}
		& input[type='text'] {
			margin: 5px 0;
		}
	}
	&.third {
		width: 33%;
		margin-right: 0.5%;
		float: left;
		&.last {
			margin-right: 0;
		}
		& input[type='text'] {
			margin: 5px 0;
		}
	}
	&.long {
		clear: both;
		overflow: hidden;
	}
	&.exp-first {
		width: 30%;
		float: left;
	}
	&.exp-last {
		width: 36%;
		float: left;
	}
}
input[type=radio] + label {
    cursor: pointer;
    display: inline-block;
    margin: 0px 5px;
    font-family: $mainfont;
    font-weight: 600;
    border: none;
    padding: 8px 30px;
    background-color: #eee;
    border-radius: 4px;
}
input[type=radio] {
    display: none;
    margin: 10px;
}
input[type=radio]:checked + label {
    background-image: none;
    color: #fff;
    cursor: default;
    background-color: #78c543 !important;
    border: none;
    margin-right: 5px;
    box-shadow: 2px 2px 0px #71b628,1px 1px 0px #71b628, 3px 3px 0px #71b628;
}
input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"]
{
	outline: 0;
	border: none;
	padding-left: 10px;
	padding-right: 10px;
	height: 46px;
	line-height: 46px;
	border-radius: 3px;
	box-shadow: none;
	background-color: rgba(255, 255, 255, 0.8);
	border: 1px solid #ddd;
	color: $grey;
	max-width: 100%;
	text-transform: uppercase;
	margin: 12px auto;
	width: 100%;
}
select {
	outline: 0;
	border: none;
	padding-left: 10px;
	padding-right: 10px;
	height: 46px;
	border-radius: 3px;
	box-shadow: none;
	background-color: rgba(255, 255, 255, 0.8);
	border: 1px solid #ddd;
	color: $grey;
	max-width: 100%;
	text-transform: uppercase;
	margin: 12px auto;
}
select option:checked { color: $grey; }
input[type="checkbox"] {
	float: left;
	margin: 6px 5px 0 20px;
}
#c-submit {
	float: right;
	color: #fff;
	padding: 14px 26px;
	font-size: 1.7rem;
    border: 0;
    background-color: #aaa;
    border-radius: 0;
    text-transform: uppercase;
    font-family: $mainfont;
    -webkit-transition: all 0.6s ease-in-out;
	-moz-transition: all 0.6s ease-in-out;
	-o-transition: all 0.6s ease-in-out;
	transition: all 0.6s ease-in-out;
	&:hover {
		background-color: $grey;
	}
}
textarea {
	padding: 10px;
	outline: 0;
	border: none;
	border-radius: 3px;
	box-shadow: none;
	background-color: #d8d8d8;
	color: $grey;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.8);
	max-width: 100%;
	max-height: 100px !important;
	margin: 6px auto;
	text-transform: uppercase;
	border: 1px solid #ddd;
}

input[type="text"]:focus, input[type="password"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="month"]:focus, input[type="week"]:focus, input[type="email"]:focus, input[type="number"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="color"]:focus, textarea:focus, select:focus, select:hover{
	background-color: rgba(106, 106, 106, 0.54);
}