//FEED
$blockpadding: 10px;

.section-wrap {
  padding: $blockpadding 0;
}

.section-rows {
  display: block;
  overflow: hidden;
  padding: $blockpadding 0 0 0;
  & .sc-row {
    height: 475px;
    width: 100%;
    overflow: hidden;
    color: $grey;
    padding: $blockpadding 0;
    & .row-third {
      float: left;
      width: 32.667%;
      margin-right: 1%;
      height: 100%;
      background-color: $white;
      color: $grey;
      &:last-child {
        margin-right: 0;
      }
      & .info-line {
        color: $grey;
        font-family: "Arima Madurai", serif;
        font-weight: 200;
        font-size: 2rem;
        text-align: center;
        padding-top: 40px;
        padding-bottom: 20px;
        width: 100%;
        border-bottom: 1px solid #ddd;
        &.first {
          padding-top: 0;
        }
      }
      &.hours {
        padding: 15px 50px;
        width: 29.667%;
      }
      &.info {
        padding: 15px 50px;
        width: 29.667%;
      }
      &.map {
        width: 38.667%;
      }
    }
    & .info-block {
      float: left;
      width: 39.5%;
      height: 100%;
      margin-right: 0.5%;
      padding: 70px 40px;
      &.grey {
        background-color: $grey;
      }
      &.gold {
        background-color: $gold;
        padding: 98px 40px;
      }
      & h2 {
        font-family: $titlefont;
        text-align: center;
        font-size: 4.5rem;
      }
      & p {
        font-family: $mainfont;
        font-weight: 100;
        font-size: 2rem;
      }
    }
  }
}

.row-info-heading {
  color: $grey !important;
}

.portfolio-showcase {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2rem 1rem;
  .portfolio-project {
    margin: 2rem 1rem 4rem 1rem;
    border-bottom: 1px solid #565656;
    color: #565656;
    & a {
      text-decoration: none;
    }
    & h2 {
      color: #565656 !important;
      font-size: 1.5rem !important;
      margin: 3rem 0;
    }
    & .img-block {
      height: 350px;
      width: 300px;
      background-color: $grey;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      &#glenview-park {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/glenview-park/head-front.jpg");
      }
      &#homerama2018 {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/homerama-2018/head-front.jpg");
      }
      &#homerama2017 {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/homerama-2017/head-front.jpg");
      }
      &#homerama1116 {
        background-image: url("/img/portfolio/homerama-2016-11/head-front.jpg");
      }
      &#homerama11 {
        background-image: url("/img/portfolio/homerama-2015-11/head-front.jpg");
      }
      &#homerama7 {
        background-image: url("/img/portfolio/homerama-2015-7/head-front.jpg");
      }
      &#homerama2014 {
        background-image: url("/img/portfolio/homerama-2014/head-front.jpg");
      }
      &#terraces {
        background-image: url("/img/portfolio/terraces/head-front.jpg");
      }
      &#autumn-ridge {
        background-image: url("/img/portfolio/autumn-ridge/head-front.jpg");
      }
      &#wooded-meadow {
        background-image: url("/img/portfolio/wooded-meadow/head-front.jpg");
      }
      &#rock-moss {
        background-image: url("/img/portfolio/rock-moss/head-front.jpg");
      }
      &#hannah {
        background-image: url("/img/portfolio/hannah/head-front.jpg");
      }
      &#fenwick {
        background-image: url("/img/portfolio/fenwick/head-front.jpg");
      }
      &#persimmon {
        background-image: url("/img/portfolio/persimmon/head-front.jpg");
      }
      &#welcome-home-2019 {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/welcome-home-tour-2019/thumbs/1.jpg");
      }
      &#green-goddess {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/green-goddess/thumbs/2.jpg");
      }
      &#carolina {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/carolina/thumbs/1.jpg");
      }
      &#ashley-redman {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/ashley-redman/sm/2.jpg");
      }
      &#beech-spring-farm {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/beech-spring-farm/sm/1.jpg");
      }
      &#beckley-crossing {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/beckley-crossing/sm/1.jpg");
      }
      &#carefree-place {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/carefree-place/sm/1.jpg");
      }
      &#civitas-financial {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/civitas-financial-commercial/sm/1.jpg");
      }
      &#fairway-mortgage {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/fairway-mortgage-commercial/sm/1.jpg");
      }
      &#farm-ridge-rd {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/farm-ridge-rd/sm/1.jpg");
      }
      &#keller-williams-louisville {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/keller-williams-louisville/sm/1.jpg");
      }
      &#loi-rd {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/loi-rd/sm/1.jpg");
      }
      &#long-grove-way {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/long-grove-way/sm/1.jpg");
      }
      &#long-grove-way-2 {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/long-grove-way-2/sm/1.jpg");
      }
      &#meadow-bluff-way {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/meadow-bluff-way/sm/1.jpg");
      }
      &#meadow-bluff-way-2 {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/meadow-bluff-way-2/sm/1.jpg");
      }
      &#shakes-creek-drive {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/shakes-creek-drive/sm/1.jpg");
      }
      &#spring-farm {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/spring-farm/sm/1.jpg");
      }
      &#stonefield-lane {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/stonefield-lane/sm/1.jpg");
      }
      &#butterweed-court {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/butterweed-court/sm/1.jpg");
      }
      &#coltsfoot-trace {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/coltsfoot-trace/sm/1.jpg");
      }
      &#featherbell {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/featherbell/sm/1.jpg");
      }
      &#flat-rock-road {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/flat-rock-road/sm/1.jpg");
      }
      &#floyds-knobs {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/floyds-knobs/sm/1.jpg");
      }
      &#frances-rosenbaum {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/frances-rosenbaum/sm/1.jpg");
      }
      &#glenview-park-2019 {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/glenview-park-2019/sm/1.jpg");
      }
      &#glenview-springs {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/glenview-springs/sm/1.jpg");
      }
      &#hart-glenview {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/hart-glenview/sm/2.jpg");
      }
      &#homerama-2013 {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/homerama-2013/sm/1.jpg");
      }
      &#homerama-2014 {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/homerama-2014/sm/1.jpg");
      }
      &#homerama-2015-house-7 {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/homerama-2015-house-7/sm/1.jpg");
      }
      &#homerama-2015-house-11 {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/homerama-2015-house-11/sm/1.jpg");
      }
      &#homerama-2016-house-2 {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/homerama-2016-house-2/sm/1.jpg");
      }
      &#homerama-2016-house-25 {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/homerama-2016-house-25/sm/1.jpg");
      }
      &#impatiens-street {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/impatiens-street/sm/1.jpg");
      }
      &#margaret-vibbert {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/margaret-vibbert/sm/1.jpg");
      }
      &#rock-moss-road {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/rock-moss-road/sm/1.jpg");
      }
      &#the-terraces-apartments {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/the-terraces-apartments/sm/1.jpg");
      }
      &#waterside-park-place {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/waterside-park-place/sm/1.jpg");
      }
      &#welcome-home-tour-2019 {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/welcome-home-tour-2019/thumbs/1.jpg");
      }
      &#woods-of-st-thomas {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/woods-of-st-thomas/sm/1.jpg");
      }
      &#interlink-mortgage {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/interlink-mortgage/sm/1.jpg");
      }
      &#limestone-title {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/limestone-title/sm/1.jpg");
      }
      &#louisville-insurance {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/louisville-insurance/sm/1.jpg");
      }
      &#southern-metals {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/southern-metals/sm/1.jpg");
      }
      &#the-farmhouse {
        background-image: url("https://s3.amazonaws.com/setthestage/galleries/the-farmhouse/sm/1.jpg");
      }
    }
    & .porfolio-project-overlay {
      transition: 0.5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }
  }
}

.portfolio-project:hover .img-block {
  opacity: 0.7;
}

@media (min-width: 768px) {
  .portfolio-showcase {
    padding: 2rem 6rem;
  }
}

@media (min-width: 992px) {
  .portfolio-showcase {
    padding: 2rem 8rem;
    margin: 0 6rem;
  }
}

@media (min-width: 1500px) {
  .portfolio-showcase {
    padding: 2rem 10rem;
    margin: 0 10rem;
  }
}

.line-div {
  margin: 40px auto;
  width: 40px;
  height: 1px;
  background-color: $white;
  clear: both;
  display: block;
  &.grey {
    background-color: #ccc;
  }
}
.line-div-left {
  @extend .line-div;
  margin: 18px 0 18px 1px;
  width: 100px;
  &.grey {
    background-color: #ccc;
  }
}
.block-btn {
  font-family: $titlefont;
  color: $white;
  border: 1px solid $white;
  text-align: center;
  padding: 50px;
  text-transform: uppercase;
  margin: 0 auto;
  width: 230px;
  display: block;
  font-size: 2rem;
  clear: both;
  @include animate(0.6s);
  &:hover {
    background-color: rgba(255, 255, 255, 0.6);
    color: $grey;
    text-decoration: none;
    cursor: pointer;
  }
}

//CONTACT
.contact {
  padding: 80px 0 80px 0;
  overflow: hidden;
  display: block;
  width: 100%;
  clear: both;
  position: relative;
  background-image: url("/img/elements/halftone.png");
  & h2,
  p {
    color: $grey;
    padding-left: 1px;
  }
}
.contact-wrapper {
  width: 900px;
  margin: 0 auto;
  position: relative;
  & form {
    position: relative;
  }
}

.contact-title {
  display: block;
  width: 250px;
  margin: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: $grey;
  text-align: center;
  font-size: 2.4rem;
  line-height: 4.6rem;
  color: #fff;
  text-transform: uppercase;
  font-family: $mainfont;
  &:after {
    content: "";
    position: absolute;
    right: -23px;
    top: 0px;
    border-left: 23px solid #0052bd;
    border-top: 23px solid transparent;
    border-bottom: 23px solid transparent;
  }
}

//SOCIAL
.social-bar {
  background-color: #d8a550;
  height: 130px;
  display: block;
  width: 100%;
  clear: both;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
}
.line-texture {
  background-image: url("/img/elements/groovepaper.png");
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}
.social-wrap {
  width: 1100px;
  height: 100%;
  display: block;
  margin: 0 auto;
  & .social-block {
    width: 23%;
    float: left;
    margin: 0 1% 40px;
    height: 100%;
    z-index: 10;
    position: relative;
    background-color: $gold;
    padding: 25px;
    -webkit-transition: all 0.6s ease-in-out;
    -moz-transition: all 0.6s ease-in-out;
    -o-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
    & i {
      color: rgba(39, 39, 39, 0.16);
      text-align: center;
      font-size: 4rem;
      width: 100%;
      -webkit-transition: all 0.6s ease-in-out;
      -moz-transition: all 0.6s ease-in-out;
      -o-transition: all 0.6s ease-in-out;
      transition: all 0.6s ease-in-out;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.4);
      & i {
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }
}

@media (min-width: 992px) {
  .social-wrap {
    display: flex;
    justify-content: center;
  }
}
