.slicknav_menu {
  display: none;
}
.slicknav_menu h3 {
  font-size: 1.8rem;
  margin: 0;
  color: #333;
}
.mobile {
  display: none;
}
@media screen and (max-width: 1500px) {
  .social {
    display: none;
  }
}

@media screen and (max-width: 1350px) {
  .section-rows .sc-row .row-third {
    &.hours {
      width: 32.667%;
    }
    &.info {
      width: 32.667%;
    }
    &.map {
      width: 32.667%;
    }
  }
}

@media screen and (max-width: 1300px) {
  .header-slider .upper-emblem {
    left: 20px;
    bottom: 90px;
    right: auto;
    & img {
      max-width: 150px !important;
    }
  }
  .header-slider .emblem {
    width: 450px;
  }
  .header-slider .slogan {
    font-size: 3.2rem;
    left: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .footer-wrapper {
    width: 100%;
    & .col-lg-3,
    .col-lg-4,
    .col-lg-2 {
      width: 25%;
      float: left;
    }
  }
  .section-rows .sc-row .row-third {
    &.hours {
      padding: 15px 20px;
    }
    &.info {
      padding: 15px 20px;
    }
  }
}

@media screen and (max-width: 1100px) {
  .social-wrap {
    width: 100%;
  }
  .content-wrapper {
    width: 100%;
  }
  .bio-img {
    width: 250px;
    height: 250px;
  }
  .bio-block {
    width: 60%;
    margin: 0 auto;
    & h3,
    h4 {
      text-align: center;
    }
  }
  .service-desc {
    float: none;
    width: 100%;
  }
  .services .col {
    width: 60%;
    margin: 0 auto;
    & h3 {
      text-align: center;
    }
  }
  .header-slider .upper-emblem {
    bottom: 20px;
    & img {
      max-width: 150px !important;
      height: 100px;
    }
  }
  .header-slider .slogan {
    font-size: 3rem;
    left: 10px;
  }
}

@media screen and (max-width: 1024px) {
  //HEADER
  .header-slider .slogan {
    left: 140px;
    top: 61px;
  }
  .header-slider .emblem {
    width: 300px;
  }
  .header-image,
  .header-image.tall {
    height: 300px;
  }
  //SECTIONS
  .section-rows .sc-row .info-block,
  .section-rows .sc-row .img-block {
    width: 49.5%;
  }

  //WRAPPER
  .contact-wrapper {
    width: 100%;
  }

  //DEVICE SPECIFIC HIDING/MENU
  .slicknav_menu {
    display: block;
  }
  .mobile {
    display: block;
  }
  .main-nav {
    display: none;
  }

  //PORTFOLIO
  .portfolio-item .portfolio-address {
    padding: 14px 0px 14px 130px;
    margin-top: 49px;
    margin-bottom: 5px;
  }
  .portfolio-item .gallery {
    padding-top: 0;
  }
  .portfolio-item .wrapper {
    margin: 0 auto 5px auto;
  }

  .section-rows .sc-row .row-third {
    &.hours {
      padding: 15px 20px;
      width: 49.5%;
      margin-left: 0.5%;
    }
    &.info {
      padding: 15px 20px;
      width: 49.5%;
      margin-right: 0.5%;
    }
    &.map {
      width: 100%;
      margin: 0;
      float: right;
      display: none;
    }
  }

  .map-wide {
    height: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .footer-wrapper {
    & .col-lg-3,
    .col-lg-4,
    .col-lg-2 {
      width: 50%;
      float: left;
      & img {
        width: 200px;
        margin: 0 auto;
        display: block;
      }
    }
    & #link-col,
    #desc-col {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  h1,
  h2 {
    font-size: 3.4rem;
  }

  .bio-block {
    width: 80%;
  }

  .header-image {
    height: 200px;
  }
  .header-image.tall {
    display: none;
  }

  .section-rows .sc-row .info-block,
  .section-rows .sc-row .img-block {
    width: 100%;
    min-height: 305px;
    clear: both;
    display: block;
    float: none;
    margin: 0;
  }
  .section-rows .sc-row .info-block {
    padding: 50px 40px !important;
    & h2 {
      font-size: 3.4rem;
      margin-top: 0;
    }
  }
  .section-rows .sc-row {
    height: auto;
    overflow: hidden;
    padding: 0;
  }
  .section-rows {
    padding: 0;
  }
  .block-btn {
    padding: 30px;
    width: 200px;
  }

  //SOCIAL BAR
  .social-wrap .social-block {
    width: 48%;
    margin: 10px 1% 10px 1%;
  }
  .social-bar {
    height: auto;
    overflow: hidden;
  }

  //HEADER
  .header-slider .slogan {
    font-size: 2rem;
  }

  .section-rows .sc-row .row-third {
    &.hours {
      width: 100%;
      margin: 0;
    }
    &.info {
      width: 100%;
      margin: 0;
      background-color: white;
    }
    & .info-line {
      font-size: 2rem;
      padding-bottom: 10px;
    }
    padding: 15px 20px 40px 20px !important;
  }

  .map-wide {
    height: 400px;
  }

  .gm-style-iw p {
    font-size: 1rem;
    margin-bottom: 5px;
  }
  .gm-style-iw h4 {
    font-size: 1.3rem;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 600px) {
  .services .col {
    width: 80%;
    padding: 30px;
  }
  .section-rows .sc-row .img-block {
    &#homerama2017 {
      background-image: url("https://s3.amazonaws.com/setthestage/galleries/homerama-2017/head-front-mob.jpg");
    }
    &#homerama11 {
      background-image: url("/img/portfolio/homerama-2015-11/head-front-mob.jpg");
    }
    &#homerama7 {
      background-image: url("/img/portfolio/homerama-2015-7/head-front-mob.jpg");
    }
    &#homerama2014 {
      background-image: url("/img/portfolio/homerama-2014/head-front-mob.jpg");
    }
    &#terraces {
      background-image: url("/img/portfolio/terraces/head-front-mob.jpg");
    }
    &#autumn-ridge {
      background-image: url("/img/portfolio/autumn-ridge/head-front-mob.jpg");
    }
    &#wooded-meadow {
      background-image: url("/img/portfolio/wooded-meadow/head-front-mob.jpg");
    }
    &#rock-moss {
      background-image: url("/img/portfolio/rock-moss/head-front-mob.jpg");
    }
    &#hannah {
      background-image: url("/img/portfolio/hannah/head-front-mob.jpg");
    }
    &#fenwick {
      background-image: url("/img/portfolio/fenwick/head-front-mob.jpg");
    }
    &#persimmon {
      background-image: url("/img/portfolio/persimmon/head-front-mob.jpg");
    }
  }
  &#homerama2017-head {
    background-image: url("https://s3.amazonaws.com/setthestage/galleries/homerama-2017/head-front-mob.jpg");
  }
  #homerama11-head {
    background-image: url("/img/portfolio/homerama-2015-11/head-front-mob.jpg");
  }
  #homerama7-head {
    background-image: url("/img/portfolio/homerama-2015-7/head-front-mob.jpg");
  }
  #homerama2014-head {
    background-image: url("/img/portfolio/homerama-2014/head-front-mob.jpg");
  }
  #terraces-head {
    background-image: url("/img/portfolio/terraces/head-front-mob.jpg");
  }
  #autumn-ridge-head {
    background-image: url("/img/portfolio/autumn-ridge/head-front-mob.jpg");
  }
  #wooded-meadow-head {
    background-image: url("/img/portfolio/wooded-meadow/head-front-mob.jpg");
  }
  #rock-moss-head {
    background-image: url("/img/portfolio/rock-moss/head-front-mob.jpg");
  }
  #hannah-head {
    background-image: url("/img/portfolio/hannah/head-front-mob.jpg");
  }
  #fenwick-head {
    background-image: url("/img/portfolio/fenwick/head-front-mob.jpg");
  }
  #persimmon-head {
    background-image: url("/img/portfolio/persimmon/head-front-mob.jpg");
  }

  .desktop-tablet {
    display: none;
  }
  .header-slider {
    height: 400px;
    display: block;
    clear: both;
    background-image: url("/img/head-mob.jpg");
    background-position: center;
    background-size: cover;
  }
  .profile {
    & p {
      padding: 0 20px;
    }
    & h1 {
      padding: 0 20px;
    }
  }
  .page {
    & ul {
      margin: 0 10px;
      padding: 0;
      list-style-image: none;
      list-style: none;
      & li {
        max-width: 100%;
        word-wrap: break-word;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  #c-form {
    max-width: 100%;
    padding: 0 10px;
    & p {
      display: none;
    }
  }

  .social-wrap .social-block {
    width: 100%;
    margin: 5px 0;
  }
  .footer-wrapper {
    & .col-lg-3,
    .col-lg-4,
    .col-lg-2 {
      width: 100%;
      text-align: center;
    }
  }
  .background-footer {
    height: 620px;
  }
  .footer-plc {
    height: 600px;
  }

  //HEADER
  .header-slider .slogan {
    font-size: 2rem;
  }
  .header-slider .emblem {
    bottom: 10px;
    left: 10px;
    right: auto;
    max-width: 300px;
  }
  .header-slider .upper-emblem {
    left: 11px;
  }
}

@media screen and (max-width: 400px) {
  .footer-date {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0;
    font-size: 1rem;
  }
  .section-wrap {
    padding: 0;
  }
  .contact,
  .page .section {
    padding: 20px 8px;
  }
  h2 {
    font-size: 2.5rem !important;
  }
  .line-div,
  .line-div-left {
    margin: 25px auto;
  }
  .section-rows .sc-row .info-block,
  .section-rows .sc-row .img-block {
    min-height: 255px;
  }
  .services .col {
    width: 100%;
    padding: 10px;
    background-color: transparent;
  }
  .contact {
    h2 {
      text-align: center;
    }
  }
  .form-row.half {
    width: 100%;
    margin-right: 0 !important;
    margin-left: 0 !important;
    float: none;
  }
  .portfolio-item .portfolio-address {
    font-size: 2rem;
    line-height: 3.3rem;
  }
  .bio-img {
    width: 180px;
    height: 180px;
  }
  .bio-block {
    margin-bottom: 40px;
    width: 95%;
  }
  .about-us {
    padding-top: 110px !important;
  }
  .header-slider .slogan {
    font-size: 1.4rem;
  }
}
