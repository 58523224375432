.portfolio-item {
  @extend .main-block;
  background-color: $white;
  & .wrapper {
    margin: 0 auto 10px auto;
  }
  & .portfolio-address {
    width: 100%;
    color: #fff;
    background-color: $gold;
    font-family: $titlefont;
    font-size: 3rem;
    padding: 24px 0px 24px 170px;
    margin-top: 69px;
    margin-bottom: 20px;
  }
  & .portfolio-heading {
    width: 100%;
    font-family: $titlefont;
    font-size: 3rem;
    padding-top: 2rem;
    color: $grey;
  }
  & .portfolio-hr {
    width: 40px;
    border-top: 2px solid $grey;
  }
}

.portfolio-logo {
  max-width: 300px;
  height: auto;
  vertical-align: initial;
}

.portfolio-buttons {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  a {
    text-decoration: none;
    color: #6a6a6a;
  }
  a:hover {
    text-decoration: none;
    color: #d3a04c;
  }
  .active {
    color: #d3a04c;
  }
}

@media (min-width: 768px) {
  .portfolio-buttons {
    padding-top: 25px;
  }
  .portfolio-logo {
    max-width: 450px;
    height: auto;
  }
}
