.page {
  @extend .main-block;
  & .section {
    padding: 80px 0;
    & h1 {
      margin-top: 0;
    }
  }
  & h3 {
    font-family: $mainfont;
  }
  &.margin {
    margin-top: 120px;
  }
  & ul {
    margin: 0;
    list-style-image: url(/img/elements/arrow.png);
  }
}

.content-wrapper {
  width: 1100px;
  height: 100%;
  display: block;
  margin: 0 auto;
}

.header-image {
  height: 500px;
  display: block;
  overflow: hidden;
  &#about {
    @include background-image(
      "https://s3.amazonaws.com/setthestage/galleries/team/GroupPhoto.jpg"
    );
  }
  &#services {
    @include background-image("https://setthestage.s3.amazonaws.com/services/services_header.jpg");
  }
  &#published {
    height: 515px;
    @include background-image("https://setthestage.s3.amazonaws.com/published_header_3.jpg");
    background-position: bottom center;
  }
  &#contact {
    @include background-image("/img/headers/contact.jpg");
  }
  &.tall {
    height: 600px;
  }
}

//ABOUT
.bio-img {
  height: 180px;
  width: 180px;
  margin: 40px auto 0 auto;
  background-size: cover;
  background-position: center;
  @include border-radius(50%);
  &#karista {
    background-image: url("https://s3.amazonaws.com/setthestage/galleries/team/Karista.jpg");
  }
  &#kaelin {
    background-image: url("https://s3.amazonaws.com/setthestage/galleries/team/Kaelin.jpg");
  }
  &#haley {
    background-image: url("https://s3.amazonaws.com/setthestage/galleries/team/Haley.jpg");
    background-position: 50% 5%;
  }
  &#page {
    background-image: url("https://s3.amazonaws.com/setthestage/galleries/team/Page.jpg");
  }
  &#stephanie {
    background-image: url("https://s3.amazonaws.com/setthestage/galleries/team/Stephanie.jpg");
  }
  &#hannah {
    background-image: url("https://s3.amazonaws.com/setthestage/galleries/team/Hannah.jpg");
  }
  &#katie {
    background-image: url("https://s3.amazonaws.com/setthestage/galleries/team/Katie.jpg");
  }
  &#lindsay {
    background-image: url("https://setthestage.s3.amazonaws.com/lindsay.jpg");
  }
  &#tayler {
    background-image: url("https://s3.amazonaws.com/setthestage/galleries/team/Tayler.jpg");
    background-position: 50% 15%;
  }
  &#placeholder {
    background-image: url("/img/female.jpg");
  }
}

.bio-block {
  margin-bottom: 40px;
  clear: both;
  overflow: hidden;
  min-height: 300px;
}

.bio-btn {
  background-color: $gold;
  color: $white;
  text-align: center;
  font-family: $mainfont;
  font-size: 1.7rem;
  padding: 10px 20px;
  float: left;
}

//SERVICES
.services-container {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: flex-start;
}
.col {
  padding: 25px 50px;
  margin: 10px 0;
  overflow: hidden;
  background-color: rgba(247, 247, 247, 0.71);
}
.service-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 300px;
  padding: 4rem;
}
.service-img {
  height: 200px;
  width: 200px;
}
.service-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}


//PROFILES
.profile-block {
  & img {
    width: 50%;
    margin: 20px auto;
    display: block;
    border: 2px solid $gold;
  }
}

@media(min-width: 1700px) {
  .header-image {
    &#published {
      height: 775px;
      @include background-image("https://setthestage.s3.amazonaws.com/published_header_3.jpg");
      background-position: bottom center;
    }
  }
}
