 //FOOTER 

$footerheight: 400px;

.footer-wrapper {
	width: 1100px;
	height: 100%;
 	display: block;
 	margin: 0 auto;
}
 .footer-plc {
 	width: 100%;
 	height: $footerheight;
 	display: block;
 	width: 100%;
 	clear: both;
 	position: relative;
 	z-index: 0;
 }

 .background-footer {
 	padding: 50px 0 60px 0;
 	width: 100%;
 	height: $footerheight + 20px;
 	position: fixed;
 	bottom: 0;
 	left: 0;
 	background-color: #fff;
 	background-image: url('/img/cork-wallet.png');

 	& p, span, ul li, h3 {
 		font-family: $mainfont;
 		color: #888;
 	}
 	& h3 {
 		margin-top: 15px;
 		color: #666;
 	}
 	& a {
 		font-family: $mainfont;
 		font-weight: 600;
 		&:hover {
 			color: $gold;
 			text-decoration: none;
 		}
 	}
 	& ul {
 		margin: 0;
 		list-style-image: url('/img/elements/arrow.png');
 		& li {
 			line-height: 32px;
 		}
 	}
 	& .subtitle {
 		font-weight: 400;
 	}
 	& .footer-contact {
 		& a, p {
			font-size: 14px;
 		}
 	}
 }

 .footer-icon {
 	& img {
 		max-width: 140px;
 	}
 	margin-bottom: 20px;
 }

 .footer-date {
 	position: absolute;
 	bottom: 0;
 	width: 100%;
 	margin: 0;
 	line-height: 63px;
 	text-align: center;
 	display: block;
 	font-family: $mainfont;
 	border-top: 1px solid #ddd;
 }
