//MOBILE PHONE
#phone-mob {
  position: fixed;
  top: 0px;
  line-height: 49px;
  right: 50px;
  z-index: 40;
}
//NAVIGATION
.social {
  float: right;
  margin: 0;
  & li {
    display: inline-block;
    float: left;
    margin-right: 20px;
    &:last-child {
      margin-right: 0px;
    }
  }
  & p {
    margin: 0;
    line-height: $menuheight;
    @include menufont(1.6rem);
    color: #fff !important;
  }
  & i {
    color: #fff;
    font-size: 1.8rem;
    line-height: $menuheight;
  }
  &.bottom {
    & p,
    i {
      line-height: 100px;
    }
  }
}

.phone {
  a {
    color: #fff !important;
  }
}

.main-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $menuheight;
  background-color: rgba(106, 106, 106, 0.8);
  padding-right: 180px;
  z-index: 20;
  & .menu-title {
    float: left;
    text-align: left;
    color: $grey;
    margin: 0 0 0 80px;
    font-family: $titlefont;
    text-transform: uppercase;
    line-height: $menuheight;
  }
}
.menu-wrapper {
  float: left;
  padding-left: 215px;
}
.head-overlay {
  background-image: url("/img/elements/line1.png");
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}
.menu {
  list-style: none;
  line-height: $menuheight;

  margin: 0;
  & > li {
    float: left;
    min-width: 100px;
    position: relative;
    @include animate(0.6s);

    & a,
    span {
      height: $menuheight;
      line-height: $menuheight;
      display: block;
      & h3 {
        padding-right: 30px;
        padding-left: 30px;
        @include menufont(1.6rem);
        color: white !important;
        @include animate(0.6s);
        font-weight: 300;
        margin: 0;
        line-height: $menuheight;
      }
    }

    & > ul {
      display: none;
      background-color: rgba(255, 255, 255, 0.6);
      list-style: none;
      margin-left: 0;
      padding: 0;
      & > li {
        margin: 0;
        padding: 15px 22px;
        & a {
          height: auto;
          line-height: 1;
        }
      }
    }

    &:hover {
      & > ul {
        display: block;
        position: absolute;
        left: 0;
      }
    }

    &:hover {
      background-color: rgba(106, 106, 106, 0.54);
      & a {
        text-decoration: none;
        & h3 {
          color: #fff;
        }
      }
    }
  }
}
.logo {
  position: fixed;
  overflow: hidden;
  left: 0px;
  top: 0px;
  z-index: 21;
  & img {
    height: 50px;
    width: auto;
  }
}
.highlight-item {
  background-color: rgba(226, 174, 73, 0.6);
  position: absolute;
  right: 0;
  height: $menuheight;
  top: 0;
  width: 180px;
  @include animate(0.6s);
  & a {
    font-family: $mainfont;
    height: $menuheight;
    line-height: $menuheight;
    display: block;
    & h3 {
      text-align: center;
      color: #fff;
      font-family: $mainfont;
      font-size: 1.8rem;
      line-height: $menuheight;
      width: 100%;
      margin: 0;
      letter-spacing: 1px;
      font-weight: 600;
      display: block;
      position: relative;
      z-index: 21;
      text-transform: uppercase;
      @include animate(0.6s);
    }
  }
  &:hover {
    background-color: $grey;
    & a {
      text-decoration: none;
      & h3 {
        color: #fff;
      }
    }
  }
}
.btn-stripe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("/img/elements/stripebg.png");
  background-repeat: repeat;
}
.left-highlight {
  background-color: $grey;
  position: absolute;
  left: 0;
  height: 90px;
  top: 0;
  width: 240px;
}

//SLIDER
.header-slider {
  height: 400px;
  overflow: hidden;
  position: relative;
  & .emblem {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 20;
    width: 537px;
  }
  & .slogan {
    font-family: $boldfont;
    font-size: 5rem;
    color: $white;
    display: block;
    position: absolute;
    left: 20px;
    bottom: 20px;
    z-index: 20;
    text-shadow: -3px -3px 2px rgba(106, 106, 106, 0.8);
    text-transform: uppercase;
    & .gold {
      color: $gold;
    }
  }
}

@media (min-width: 767px) {
  .menu {
    & > ul {
      background-color: rgba(106, 106, 106, 0.6);
      & > li {
        & a {
          color: #fff;
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .logo {
    position: fixed;
    overflow: hidden;
    left: 0px;
    top: 0px;
    z-index: 21;
    & img {
      height: $menuheight;
      width: auto;
    }
  }
}

@media (min-width: 1500px) {
  //SLIDER
  .header-slider {
    height: 700px;
  }
}
