.gallery {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2rem 1rem;
  & .gallery-item {
    margin: .5rem;
    width: 325px;
    position: relative;
    overflow: hidden;
    border: 5px solid $white;
    border-width: 0 5px 5px 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 300px;
    background-position: center;
    background-repeat: no-repeat;
    &:hover {
      & span {
        opacity: 1;
      }
      & img {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
      }
    }
  }
  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    @include animate(0.3s);
  }
  & span {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px 0px 10px 0px;
    z-index: 1;
    text-shadow: 0 0 5px rgba(0, 0, 0, 1);
    color: #fff;
    font-size: 1.8rem;
    text-transform: uppercase;
    opacity: 0;
    @include animate(0.3s);
    font-family: $mainfont;
    width: 200px;
    text-align: center;
    background-color: rgba(106, 106, 106, 0.6);
  }
}

.gallery-section {
  padding-top: 100px;
}

@media(min-width: 768px) {
  .gallery {
    padding: 4rem 6rem;
  }
}

@media(min-width: 992px) {
  .gallery {
    padding: 4rem 8rem;
  }
}

@media(min-width: 1500px) {
  .gallery {
    padding: 4rem 10rem;
    // margin: 0 10rem; TODO:
  }
}
